import * as React from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"

const RecentScholarlyImpactPage = () => (
  <Layout>
    <Seo title="Industry Experience" />
    <div id="content">
      <div id="feature_box"></div>
      <div class="post_box top" id="post-27">
        <div class="format_text">
          <table style={{ marginTop: "0px" }}>
            <tbody>
              <h1>Industry Experience</h1>
              <tr>
                <td>
                  <h2>Fast Food</h2>
                  <p>
                    <span
                      style={{
                        float: "left",
                        margin: "0 0.5em 0.5em 0.5em",
                        textAlign: "left",
                        display: "block",
                      }}
                    >
                      <StaticImage
                        src="../images/shutterstock_36897496-Fast-Food-300x201.jpeg"
                        alt="Seats at a diner"
                      />
                    </span>
                    Served for several years as Director of fast food operator
                    Orange Julius (over 800 units world-wide), and served as
                    Chairman of the Committee of Independent Directors in the
                    sale of the company to International Dairy Queen, now owned
                    by Warren Buffett’s{" "}
                    <a href="http://www.berkshirehathaway.com/">
                      Berkshire Hathaway
                    </a>
                    .
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <h2 class="market">Financial and Capital Markets</h2>
                  <p>
                    <span
                      style={{
                        float: "left",
                        margin: "0 0.5em 0.5em 0.5em",
                        textAlign: "left",
                        display: "block",
                      }}
                    >
                      <StaticImage
                        src="../images/shutterstock_3583323-Financial-Services2-300x226.jpeg"
                        alt="Stock market going up"
                      />
                    </span>
                    For many years, he provided investment banking capital
                    formation and merger and acquisition services to the
                    financial services industry. For a period of time at the
                    investment banking firm of Donaldson, Lufkin &amp; Jenrette
                    he held the title of Director, Mortgage Banking Industry
                    Practice. He has been a contributor of many financial
                    service articles on investment banking and corporate
                    finance-related topics for numerous industry trade
                    publications, and newspapers such as The American Banker,
                    The Baltimore Sun and The Washington Post. He has written
                    extensively for <em>Mortgage Banking</em> and has frequently
                    been quoted in the financial press. He is also author of{" "}
                    <em>
                      TRAUTMAN’S GUIDE TO MORTGAGE BANKING AND LOAN SERVICING
                      PORTFOLIO ACQUISITIONS
                    </em>
                    , 1970-81 (approximate 700pp. and out of print).
                  </p>
                  <p class="breakhere">
                    He has approximately thirty years of investment industry
                    experience. Recognized as an expert in capital formation, he
                    was asked to provide testimony (economic analysis and policy
                    strategy as an investment banker) before hearings of the
                    United States Congress.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <h2>Software and Technology</h2>
                  <p>
                    <span
                      style={{
                        float: "left",
                        margin: "0 0.5em 0.5em 0.5em",
                        textAlign: "left",
                        display: "block",
                      }}
                    >
                      <StaticImage
                        src="../images/shutterstock_14331664-New-Media-273x300.jpeg"
                        alt="Person's outline in code"
                      />
                    </span>
                    Larry has an avid interest in technology and software having
                    conducted post-graduate studies (computer science &amp;
                    management information systems) at The{" "}
                    <a href="http://www.utdallas.edu/">
                      University of Texas at Dallas
                    </a>
                    . He became aware early of the power available through the
                    use of Computer Assisted Software Engineering (“CASE” tools)
                    and has maintained an interest in software publishing and
                    development. He was an early member of the Software
                    Publishers Association. During 1999-2001, he was President
                    of the Dallas Internet Society. He has been a member of the{" "}
                    <a href="http://www.dfwima.org/">
                      DFW Interactive Marketing Association
                    </a>
                    , a forum for interactive professionals, businesses and
                    educators in the Dallas-Ft. Worth area dedicated to sharing
                    ideas, information and best practices that will help define
                    and develop the growing interactive marketing industry and
                    its disciplines.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <h2>Oil and Gas</h2>
                  <p>
                    <span
                      style={{
                        float: "left",
                        margin: "0 0.5em 0.5em 0.5em",
                        textAlign: "left",
                        display: "block",
                      }}
                    >
                      <StaticImage
                        src="../images/shutterstock_16086061-Oil-Gas-300x210.jpeg"
                        alt="Oil drills"
                      />
                    </span>
                    His interest in the oil and gas industry was strengthened
                    during law school, where he served as President of the
                    Energy Law Association at Oklahoma City University School of
                    Law. He has industry experience, having served as a
                    corporate director of several companies engaged in oil and
                    gas exploration. Along with Harold R. Logan, Sr. (fmr. Vice
                    Chairman of W.R. Grace and CEO of Grace Energy) and Dick
                    Hickok (fmr. Chairman of Main Hurdman, now KPMG), Larry was
                    a Founding Director of Comstock Tunnel &amp; Drainage Co.
                    (now Comstock Resources, Inc. NYSE: CRK), beginning with a
                    negative net worth of $32,000 (in 1985).
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <h2>Radio, Talent and Film</h2>
                  <p>
                    <span
                      style={{
                        float: "left",
                        margin: "0 0.5em 0.5em 0.5em",
                        textAlign: "left",
                        display: "block",
                      }}
                    >
                      <StaticImage
                        src="../images/shutterstock_56328688-Entertainment-300x200.jpeg"
                        alt="Oil drills"
                      />
                    </span>
                    His entrepreneurial and business experience started early.
                    At age 16, he had an on-the-air public service radio show on
                    50-thousand-watt WHOO in Orlando, Florida. Shortly
                    thereafter, he had his first business start-up, managing
                    rock-n-roll musical groups and music production. To this day
                    he considers talent management (negotiating, contracting,
                    talent development, performance production, and A&amp;R
                    strategy) maybe the best job he ever had. Certainly, it was
                    the job where he learned the most, fastest.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <h2>Real Estate</h2>
                  <p>
                    <span
                      style={{
                        float: "left",
                        margin: "0 0.5em 0.5em 0.5em",
                        textAlign: "left",
                        display: "block",
                      }}
                    >
                      <StaticImage
                        src="../images/shutterstock_23564317-Real-Estate-300x207.jpeg"
                        alt="Buildings"
                      />
                    </span>
                    Real estate is highly cyclical and a major component of our
                    national economy.&nbsp;Early in his career, Larry was hired
                    to assist in drafting curriculum for the School of Mortgage
                    Banking of the{" "}
                    <a href="http://www.mbaa.org/">
                      Mortgage Bankers Association of America
                    </a>
                    . He held various staff capacities at MBAA, serving for
                    approximately three years as industry’s liaison for all
                    “internal management” issues germane to the daily operations
                    of a mortgage banking company - including accounting,
                    banking relations, corporate planning, data processing,
                    software applications and development, internal and
                    compliance audits, mortgage loan servicing portfolio
                    valuation, and tax.&nbsp;He also served as Chief Operations
                    Officer of MBAA during his last year.
                  </p>
                  <p>
                    He was asked by Donaldson, Lufkin &amp; Jenrette to assist
                    in resolving their problem commercial real estate as well as
                    conducting sales of major commercial office and multi-family
                    housing properties.&nbsp;During this time, he held a real
                    estate sales license in the State of New York and was
                    involved in a number of commercial real estate transactions
                    and was a member of the{" "}
                    <a href="http://www.nareit.com/">
                      National Association of Real Estate Investment Trusts
                    </a>
                    .
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </Layout>
)

export default RecentScholarlyImpactPage
